import React from "react"
import Layout from "../components/Layout";
import Button from '@material-ui/core/Button';

export default function Recruitment() {
  return (
  <React.Fragment>
    <Layout>
      <h1>Join Our Study</h1>
      <br />
      <h4>Below is the eligibility criteria:</h4>
      <p style={{marginLeft:'30px'}} className='welcomeMatter'>We are recruiting people 30-55 years old who are currently smoking or have previously smoked. All participants must be able to understand English and provide informed consent.  </p>
      <h4>For more information or to participate: <Button variant="contained" size='large' onClick={()=>window.open('https://umhealthresearch.org/#studies/HUM00176147', "_blank")} style={{backgroundColor:'#B23114', color:'whitesmoke'}}>
        <b>Click Here</b> 
      </Button></h4>
      </Layout>
  </React.Fragment>
  )
}
